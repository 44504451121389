.body-container {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  min-width: 1366px;
  min-height: 768px;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin:auto;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
  color: #555555;
}

.dropzone {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #1c92c7;
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.alert-secondary {
  background-color: #f7f5f3;
  border-color: rgb(28, 146, 199);
  border-width: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.top-bottom-padding-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.top-bottom-padding-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.color-888 {
  color: #888;
  margin-bottom: 8px;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.no-right-padding {
  padding-right: 0;
}

.pointer {
  cursor: pointer;
}

.active-tab {
  background-color: #f7f5f3;
  vertical-align: middle;
  place-items: center;
}

.tab-height {
  min-height: 38px;
  vertical-align: middle;
  place-items: center;
  padding-left: 15px;
}

.btn-tags {
  margin: 5px;
  vertical-align: none;
}

/*.check-box-col-width {
  -ms-flex: 0 0 3%;
  flex: 0 0 3%;
  max-width: 3%;
}*/

.span-tags {
  border-radius: 5px 5px 5px;
  border: 1px solid rgb(0, 135, 247);
  padding: 5px;
  margin-right: 5px;
  bottom: 5px;
}

.dashed-border-bottom {
    border: 2px rgb(194, 193, 191);
    border-style: none none dashed none;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dashed-border-left {
    border: 2px rgb(194, 193, 191);
    border-style: none none none dashed;
}

@media (max-width: 767px) {
  .dashed-border-left {
    border: 2px rgb(194, 193, 191);
    border-style: dashed none none none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.addDocStatus {
    border-radius: 5px 5px 5px;
    border: 2px solid rgb(28, 146, 199);
    border-image: none;
    max-width: 100%;
    min-height: 80px;
}

.addDocResultHeader {
    border-width: 1px;
    border-style: none none solid none;
    border-color: #b5b1af;
    color: #b5b1af;
    min-height: 30px;
    text-align: center;
}

.open-signature-form {
  border-width: 1px;
  border-style: none none solid none;
  border-color: #b5b1af;
}

.span-text {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.header {
  height: 60px;
  width: 100%;
  background: #1C92C7;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
}

.btn-info {
  background-color: #1C92C7;
  cursor: pointer;
}

.btn-info:hover {
  color: gray;
  background-color: #85d3fa;
  cursor: pointer;
}

.btn-info.disabled, .btn-info:disabled {
  color: #999;
  border-color: #999;
  background-color: #dddddd;
  cursor: default;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #999;
  background: #dddddd;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  color: #999;
  background: #dddddd;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-outline-info {
  color: #1C92C7;
  border-color: #1C92C7;
  cursor: pointer;
}

.btn-outline-info:hover {
  color: gray;
  background-color: #85d3fa;
  border-color: #1C92C7;
  cursor: pointer;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #999;
  border-color: #999;
  background-color: #dddddd;
  cursor: default;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: gray;
  background-color: #85d3fa;
  border-color: #85d3fa;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
  outline-color: none;
  color: gray;
  background-color: #85d3fa;
  border-color: #dddddd;
  cursor: default;
}

.header-options {
  position: fixed;
}

.drawer {
  height: 100%;
  background: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 60;
  left: 0;
  width: 50px;
  max-width: 300px;
  transition: width 0.3s ease-out;
}

.drawer.open {
  width: 250px;
}

.table-holder {
    height: calc(100% - 170px);
    display: block;
    position: absolute;
    width: 100%;
}

.searh-bar.open + .table-holder {
  height: calc(100% - 370px);
}

.searh-bar {
  width: 100%;
  height: 30px;
  transition: height 0.3s ease-in;
}

.advanced-searh-bar {
  width: 100%;
  height: 30px;
  transition: height 0.3s ease-in;
}

.searh-bar.open {
  height: 250px;
}

.advanced-bar {
  width: 100%;
  height: 30px;
  transition: height 0.3s ease-in;
}

.advanced-bar.open {
  height: 450px;
}

.advanced-bar.open + .table-holder {
  height: calc(100% - 570px);
}

.advanced-searh-bar.open {
  height: 230px;
}

.card {
  margin-left: auto;
  margin-right: auto;
}

.body-width {
  width: 100%;
  height: calc(100vh - 60px);
}

.body-width.open {
  width: 100%;
  margin-left: 250px;
}

.edit-view.open {
  margin-left: calc(100vw - 82vw - 250px);
}

.topright-div.open {
  margin-right: 260px;
}

.edit-view {
  display:block;
  width: 100vw;
  min-width: 1650px;
  min-height: 650px;
  margin-left: calc(100vw - 82vw - 50px);
  overflow: auto;
  height: calc(100vh - 200px);
  transition: margin-left 0.3s ease-out;
}

.drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0px; 
}

.loading-div {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.loading-div-inner {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 40%;
  bottom: 60%;
  margin: auto;
  background: white;
}

.add-doc-resul-col {
  height: calc(100vh - 260px);
  overflow-y: auto;
}

.row-login {
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
}

.row-login img { 
    width:100%;
    height:100%; 
    object-fit:cover; 
}

.drawer li {
  margin: 0.5rem 0;
}

.drawer a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
}

.drawer li:hover {
  background-color: #e9ecef;
}

.drawer drawer-li:hover {
  background-color: white;
}

.header-span:hover {
  background-color: #65a4c4;
}

.fixed_header{
  table-layout: fixed;
  border-collapse: collapse;
  height: 70vh;
}

.float-right {
  float: right;
}

.fixed_header tbody{
  display:block;
  overflow: auto;
  height: 100%;
}

.fixed_header thead tr {
   display: block;
}

.fixed_header thead {
  background: gray;
  color:#fff;
}

.data-list {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.place-items {
  place-items: center;
}

.float-right {
  float: right;
}

.fixed_header_div {
  text-align: center;
  background: white;
  color: #696765;
  min-height: 34px;
}

.fixed_header_div div {
  padding-top: 8px;
  padding-bottom: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #b5b1af;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1;
}

.fixed_div {
  display:block;
  overflow: auto;
  height: calc(100% - 30px);
  max-height: 100%;
}

.no-left-right-margin {
  padding-left: 0;
  padding-right: 0;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
}

.body_div {
  line-height: 1;
  height: calc(100% - 200px);
}

.ptblg-default {
  line-height: 1.5;
}

.edit-form-view {
  height: calc(100% - 200px);
}

.FlexItem {
  height: calc(100vh - 60px);
  width: 66.6%;
  flex: 0 1 auto;
}

.FlexItem img {
  display: block;
  width: 100%;
}

.login-input-vertical-align {
  padding-top: 10%;
}

.bottomright {
  position: absolute;
  bottom: 5px;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
  right: -60px;
}

.bottomright-ie {
  position: absolute;
  bottom: 5px;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
  right: 25px;
}

.topright {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 18px;
}

.topright-div {
  position: absolute;
  top: 65px;
  right: 0px;
  margin-right: 60px;
  transition: margin-right 0.3s ease-out;
}

.status-placeholder {
  padding-top: 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  list-style: none;
  height: 38px;
}

.bg-green {
  color: green;
}

.bg-grey {
  color: grey;
}

.bg-red {
  color: red;
}

.select-status {
  text-align: center;
  border: 2px solid rgba(28, 146, 199);
  border-radius: 0.25rem;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup-info {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: auto;
}

.popup-info-inner-arr {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 300px;
  margin: auto;
  background: white;
  overflow-y: auto;
  margin-bottom: 15px;
  margin-right: 15px;
}

.popup-info-inner {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 300px;
  bottom: 15px;
  right: 15px;
  margin: auto;
  background: white;
  overflow-y: auto;
}

.popup_inner_info {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 350px;
  height: 375px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_about {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 400px;
  height: 235px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_user_info {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 800px;
  /*height: 480px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  background: white;
}

.status-placeholder {
  padding-top: 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  list-style: none;
  height: 38px;
}

.success {
  background-color: #D9FFDA;
}

.failed {
  background-color: #FFE3E3;
}

.fadein-popup div {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.fadeout-popup div {
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeout 1s; /* Firefox < 16 */
        -ms-animation: fadeout 1s; /* Internet Explorer */
         -o-animation: fadeout 1s; /* Opera < 12.1 */
            animation: fadeout 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.popup_inner {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 400px;
  height: 460px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_authors {
  width: 385px;
  height: 335px;
  overflow-y: scroll;
}

.tags-list {
  height: 75px;
  overflow-y: auto;
  padding-top: 5px;
}

.popup_inner_tags {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 500px;
  height: 245px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_confirm {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 500px;
  height: 175px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_error {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 350px;
  height: 425px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_verify-results {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 750px;
  height: 185px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_verify-results-show {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 750px;
  height: 470px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.verify-results-div {
  overflow-y: auto;
  height: 274px;
}

.popup_inner_log {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 610px;
  height: 450px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.log-div {
  overflow-y: auto;
  margin: 0px;
  margin-bottom: 15px;
  width: 575px;
  height: 315px;
}

.float-right {
    float: right;
}

/*.checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border-radius: 0.25rem;
}

.checkmark {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 0.25rem;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #1C92C7;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 4px;
  top: 3px;
  width: 6px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}*/

.popup_inner_password {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 360px;
  /*height: 370px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
  background: white;
}

.popup_inner_password_error {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 370px;
  /*height: 440px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
  background: white;
}

.popup_inner_2f {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 410px;
/*  height: 345px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  background: white;
}

.popup_inner_user_token_pin {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: 140px;
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: white;
  overflow: hidden;
}

.popup_inner_user_token_pin_confirm {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: 160px;
  background: white;
  overflow: hidden;
}

.abc-checkbox label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 2px;
  margin-left: -1.25rem;
  padding-left: 2px;
  padding-top: 3px;
  font-size: 11px;
  color: #495057;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'), url(../fonts/12093.ttf) format('truetype');
}